// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import classnames from 'classnames'

import PropTypes from '@ttn-lw/lib/prop-types'

import style from './field.styl'

const FieldContainer = ({ horizontal, children }) => (
  <div className={classnames(style.fieldContainer, { [style.horizontal]: horizontal })}>
    {children}
  </div>
)

FieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
  horizontal: PropTypes.bool,
}

FieldContainer.defaultProps = {
  horizontal: false,
}

export default FieldContainer
